import React from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";

const TextAreaInput = ({ className, label, empty, view, rows, ...props }) => {
  return (
    <div
      className={cn(
        styles.field,
        { [styles.empty]: empty },
        { [styles.view]: view },
        className
      )}
    >
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.wrap}>
        <textarea className={styles.input} rows={4} {...props} />
      </div>
    </div>
  );
};

export default TextAreaInput;
