export const testimonials = [
  {
    name: "Rouba",
    type: "Gérante d'entreprise",
    avatar: "/images/avatar-1.jpg",
    review: [
      {
        author: "Rouba",
        description: "Gérante d'entreprise",
        image: "/images/avis-pic1.jpg",
        image2x: "/images/avis-pic1@2x.jpg",
        comment:
          "Une formation qui a grandement contribué à l'enrichissement de mes compétences en tant que gérante.",
      },
    ],
  },
  {
    name: "Jordan",
    type: "Gérant Home Made",
    avatar: "/images/avatar-2.jpg",
    review: [
      {
        author: "Jordan",
        description: "Gérant Home Made",
        image: "/images/avis-pic2.jpg",
        image2x: "/images/avis-pic2@2x.jpg",
        comment:
          "Grâce à la formation de management, j’ai une meilleure approche vis à vis de mon personnel.",
      },
    ],
  },
  {
    name: "Michelle",
    type: "En reconversion",
    avatar: "/images/avatar-3.jpg",
    review: [
      {
        author: "Michelle",
        description: "En reconversion",
        image: "/images/avis-pic3.jpg",
        image2x: "/images/avis-pic3@2x.jpg",
        comment:
          "Le bilan de compétence m’a permis de savoir qui je suis et m’a permis de me motiver dans mon projet de reconversion professionnelle.",
      },
    ],
  },
  {
    name: "Jacques",
    type: "Responsable d’exploitation",
    avatar: "/images/avatar-4.jpg",
    review: [
      {
        author: "Jacque",
        description: "Responsable d’exploitation",
        image: "/images/avis-pic4.jpg",
        image2x: "/images/avis-pic4@2x.jpg",
        comment:
          "Grâce à la formation en gestion des conflits, je gère davantage mes équipes et ainsi la production.",
      },
    ],
  },
];
