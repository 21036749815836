import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";

const Item = ({ className, item, alternance }) => {
  const isNotAlternanceAvailable = alternance === 0;
  const isAlternanceAvailable = alternance === 1;

  return (
    <Link
      className={cn(
        className,
        styles.card,
      )}
      to={`/formation/${item.id}`}
    >
      <div className={styles.preview}>
        <img src={item.src} alt="Formations" loading="lazy" />
        {isAlternanceAvailable && (
          <div className={cn("category", styles.category)}>
           Alternance
          </div>
        )}
      </div>
      <div className={styles.body}>
        <div className={styles.line}>
          <div className={styles.title}>{item.title}</div>
        </div>
        <div className={styles.foot}>
          <div className={styles.flex}>
            <div className={styles.nbHeure}>{item.nbHeure}</div>
            {isNotAlternanceAvailable && (
              <div className={styles.price}>
                <div className={styles.actual}>{item.priceActual}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Item;
