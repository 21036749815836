import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Main.module.sass";

const Main = () => {
  return (
    <div className={cn("section-mb80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          <picture>
            <source
              media="(max-width: 500px)"
              srcSet="/images/content/main.png"
            />
            <img src="/images/content/main.png" alt="Main" />
          </picture>
          <div className={styles.wrap}>
            <h1 className={cn("hero", styles.title)}>Wake Up Success</h1>
            <div className={cn("info", styles.info)}>
              Votre réussite, Notre succès.
            </div>
            <Link className={cn("button", styles.button)} to="/contact">
              Contacter
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
