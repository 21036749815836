import React, { useState } from "react";
import cn from "classnames";
import styles from "./Footer.module.sass";
import { Link } from "react-router-dom";
import Image from "../Image";
import Icon from "../Icon";
import Theme from "../Theme";

const items = [
  {
    title: "Accueil",
    url: "/",
  },
  {
    title: "Formations",
    url: "/Formations",
  },
  {
    title: "Contact",
    url: "/contact",
  },
  {
    title: "Conditions générales",
    url: "/condition-générales",
  },
  {
    title: "Réglement intérieur",
    url: "/réglement-Intérieur",
  },
];

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/Wake Up Success.webp"
                srcDark="/images/Wake Up Success.webp"
                alt="Fleet"
              />
            </Link>
            <div className={styles.box}>
              <span>Dark theme</span>
              <Theme className={styles.theme} />
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.menu}>
              {items.map((x, index) => (
                <Link className={styles.link} to={x.url} key={index}>
                  {x.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            © 2023 Wake Up Success LLC. Tous droits réservés.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
