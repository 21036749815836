import React, { useState } from "react";
import cn from "classnames";
import styles from "./Newsletter.module.sass";
import Image from "../Image";
import { Link } from "react-router-dom";

const gallery = [
  {
    src: "/images/content/newsletter-pic-1.png",
    srcSet: "/images/content/newsletter-pic-1@2x.png",
    srcDark: "/images/content/newsletter-pic-1.png",
    srcSetDark: "/images/content/newsletter-pic-1@2x.png",
  },
  {
    src: "/images/content/newsletter-pic-2.png",
    srcSet: "/images/content/newsletter-pic-2@2x.png",
    srcDark: "/images/content/newsletter-pic-dark-2.png",
    srcSetDark: "/images/content/newsletter-pic-dark-2@2x.png",
  },
  {
    src: "/images/content/newsletter-pic-3.png",
    srcSet: "/images/content/newsletter-pic-3@2x.png",
    srcDark: "/images/content/newsletter-pic-dark-3.png",
    srcSetDark: "/images/content/newsletter-pic-dark-3@2x.png",
  },
  {
    src: "/images/content/newsletter-pic-4.png",
    srcSet: "/images/content/newsletter-pic-4@2x.png",
    srcDark: "/images/content/newsletter-pic-dark-4.png",
    srcSetDark: "/images/content/newsletter-pic-dark-4@2x.png",
  },
];

const Newsletter = ({ classSection, title }) => {

  return (
    <div className={cn("section", classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>
            Nous contacter
            <span role="img" aria-label="firework">
              🎉
            </span>
          </h2>
          <div className={styles.info}>
            Besoin d'Aide ou d'Informations Supplémentaires ? Nous serons ravis de accompagner.
          </div>
          <Link className={cn("button", styles.button)} to="/contact">
            Contacter
          </Link>
        </div>
        <div className={styles.gallery}>
          {gallery.map((x, index) => (
            <div className={styles.preview} key={index}>
              <Image
                srcSet={`${x.srcSet} 2x`}
                srcSetDark={`${x.srcSetDark} 2x`}
                src={x.src}
                srcDark={x.srcDark}
                alt="Newsletter"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
