import React from "react";
import cn from "classnames";
import styles from "./Control.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";

const Control = ({ className }) => {
  return (
    <div className={cn(className, styles.control)}>
      <Link
        className={cn("button-stroke button-small", styles.button)}
        to={"/formations"}
      >
        <Icon name="arrow-left" size="10" />
        <span>Retour</span>
      </Link>
    </div>
  );
};

export default Control;
