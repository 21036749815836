import React from "react";
import cn from "classnames";
import styles from "./Services.module.sass";
import Item from "./Item";

const services = [
  {
    image: "/images/Présentiel.png",
    title: "Formation en présentiel",
    content:
      "L’école Wake Up Success réinvente l'apprentissage avec ses formations en présentiel. Vivez une expérience immersive où interaction et collaboration sont les clés. Nos salles de classe, équipées à la pointe, offrent un environnement idéal pour stimuler l'engagement et favoriser l'échange d'idées. Avec des formateurs experts et passionnés, chaque session est une occasion unique de développer vos compétences et d'élargir votre réseau professionnel.",
  },
  {
    image: "/images/Learning.png",
    title: "Formation à distance",
    content:
      "À l'école Wake Up Success, nous brisons les barrières géographiques avec nos formations à distance. Grâce à des technologies avancées, accédez à un contenu pédagogique de qualité depuis le confort de votre domicile. Les cours en ligne flexibles s'adaptent à votre rythme de vie, offrant une autonomie sans précédent dans votre apprentissage. Connectez-vous à une communauté d'apprenants mondiale et élevez votre savoir-faire où que vous soyez.",
  },
  {
    image: "/images/Hybride.png",
    title: "Formation Hybride",
    content:
      "L'école Wake Up Success propose des formations hybrides, combinant le meilleur des mondes présentiel et distanciel. Cette modalité flexible permet de profiter de la dynamique collective des cours sur site et de l'autonomie des modules en ligne. Avec cette approche, adaptez votre parcours éducatif à vos besoins spécifiques tout en bénéficiant d'un suivi personnalisé. Les formations hybrides sont la réponse idéale pour ceux qui cherchent à concilier vie professionnelle et développement personnel.",
  },
];

const Services = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage", styles.stage)}>
            Notre Engagement envers Votre Réussite
          </div>
          <h2 className={cn("h2", styles.title)}>Notre pédagogie</h2>
        </div>
        <div className={styles.list}>
          {services.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
