import React from "react";
import cn from 'classnames';
import Main from "./Main";
import Services from "./Services";
import Faq from "./Faq";
import Newsletter from "../../components/Newsletter";
import styles from "./Cars.module.sass"



const Cars = () => {


  return (
    <>
      <Main />
      <div className={cn(styles.section)}>
        <div className={cn(styles.wrapper)}>
          <div className={cn(styles.imageWrapper)}>
            <img src="./images/Illustration1.png" alt="Descriptive Alt Text" />
          </div>
          <div className={cn(styles.content)}>
            <h1>Wake Up Success</h1>
            <p>
              Wake Up Success est une institution de formation distinguée. Notre établissement est certifié Qualiopi, ce qui témoigne de l'excellence et de la rigueur de nos programmes de formation.
              <br /><br />
              Nous nous spécialisons dans le développement de compétences professionnelles essentielles, en proposant des cursus variés dans des domaines clés tels que le commerce, le recrutement, la gestion opérationnelle, le marketing, la communication, et l'entrepreneuriat. Chaque parcours de formation est conçu pour être personnalisé, répondant ainsi aux besoins individuels et soutenant chaque apprenant dans l'atteinte de ses objectifs professionnels.
              <br /><br />
              À Wake Up Success, nous sommes engagés à vous doter des connaissances et de l'expertise requises pour exceller dans votre milieu professionnel et propulser votre carrière.            </p>
          </div>
        </div>
        <div className={cn(styles.wrapper)}>
          <div className={cn(styles.content)}>
            <h1>Nos valeurs</h1>
            <p>
              Chez Wake Up Success, notre dévouement se traduit par l'offre de formations de qualité supérieure, méticuleusement conçues pour répondre aux besoins uniques de chaque apprenant. Notre équipe, composée de formateurs experts et passionnés, est profondément investie dans votre réussite professionnelle. Elle vous accompagne avec soin et expertise à chaque étape de votre parcours éducatif.
              <br /><br />
              Nous croyons fermement que l'éducation doit être personnalisée et orientée vers des résultats concrets. C'est pourquoi nos programmes sont élaborés pour vous doter des compétences clés et des connaissances pratiques indispensables à la réalisation de vos aspirations professionnelles. En vous joignant à nous, vous vous engagez dans un processus d'apprentissage dynamique, enrichissant et parfaitement aligné sur vos objectifs de carrière.            </p>
          </div>
          <div className={cn(styles.imageWrapper)}>
            <img src="./images/Illustration2.png" alt="Descriptive Alt Text" />
          </div>
        </div>
        <div className={cn(styles.wrapper)}>
          <div className={cn(styles.imageWrapper)}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1265.0685294970954!2d3.061992538767185!3d50.6431456570628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32b9adbbb9599%3A0xe5cb7ec0156e9fc6!2sWAKE%20UP%20SUCCESS!5e0!3m2!1sfr!2sfr!4v1701940880933!5m2!1sfr!2sfr" width="600" height="450" style={{ borderRadius: 30 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className={cn(styles.content)}>
            <h1>Accessible à Tous, Proche de Tout</h1>
            <p>
              L'école Wake Up Success est située en plein cœur de Lille, offrant une position centrale à la fois pratique et accessible. Pour ceux qui se déplacent via les transports en commun, nous sommes à une courte distance des arrêts de bus, stations de métro et de la gare principale, assurant ainsi une facilité d'accès exceptionnelle pour tous nos visiteurs.<br /><br />
              Conscients de l'importance de l'accessibilité pour tous, nous avons pris des mesures spécifiques pour accueillir les personnes à mobilité réduite. Nos locaux sont équipés pour garantir une navigation aisée et confortable pour chacun, avec des entrées adaptées, des ascenseurs et des installations conformes aux normes d'accessibilité.<br /><br />
              Les bureaux de Wake Up Success sont ouverts au public, invitant étudiants, professionnels et curieux à découvrir nos espaces dédiés à l'enseignement et au partage de connaissances. L'accessibilité est au cœur de nos préoccupations, et nous nous engageons à fournir un environnement inclusif où tous les apprenants peuvent prospérer sans obstacle.
            </p>
          </div>
        </div>

      </div>
      <div className={cn("section-partners", styles.sectionPartners)}>
        <div className={cn("container", styles.container)}>
          <h2 className={cn("title-center", styles.titleCenter)}>Nos partenaires</h2>
          <p className={styles.subtitle}>Collaborations stratégiques pour votre réussite</p>
          <div className={cn("grid", styles.grid)}>
            <div className={styles.partner}><img src="./images/datadocké.png" alt="Datadock" /></div>
            <div className={styles.partner}><img src="./images/Haut de france.png" alt="Datadock" /></div>
            <div className={styles.partner}><img src="./images/pôle emploi.png" alt="Pôle Emploi" /></div>
            <div className={styles.partner}><img src="./images/Union européen.png" alt="Qualiopi" /></div>
          </div>
          <div className={cn("grid", styles.grid)}>
            <div className={styles.partner}><img src="./images/mon compte formation.png" alt="Mon Compte Formation" /></div>
            <div className={styles.partner}><img src="./images/INRC.png" alt="Mon Compte Formation" /></div>
            <div className={styles.partner}><img src="./images/Charte diversite.png" alt="Pôle Emploi" /></div>
            <div className={styles.partner}><img src="./images/Qualiopi.png" alt="Qualiopi" /></div>
            <div className={styles.partner}><img src="./images/île de france.png" alt="Qualiopi" /></div>

          </div>
        </div>
      </div>

      <Services />
      <Faq />
      <Newsletter />
    </>
  );
};

export default Cars;
