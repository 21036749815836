
export const categories2 = [
  {
    title: "Management",
    content: "Développez vos compétences de leadership.",
    counter: "8 Formations",
    icon: "management",
    url: "/formations",
  },
  {
    title: "Commercial & Vente",
    content: "Optimisez vos compétences en vente.",
    counter: "5 Formations",
    icon: "commercial",
    url: "/formations",
  },
  {
    title: "Marketing",
    content: "Maîtrisez les stratégies de marketing.",
    counter: "9 Formations",
    icon: "marketing",
    url: "/formations",
  },
  {
    title: "Formation entreprise",
    content: "Améliorez la performance de votre entreprise.",
    counter: "4 Formations",
    icon: "formation",
    url: "/formations",
  },
  {
    title: "Bureautique",
    content: "Maîtrisez les outils de productivité. (Word, Excel, ppt)",
    counter: "2 Formations",
    icon: "bureautique",
    url: "/formations",
  },
  {
    title: "Informatique",
    content: "Acquérez des compétences en développement informatique.",
    counter: "1 Formation",
    icon: "informatique",
    url: "/formations",
  },
  {
    title: "Immobilier",
    content: "Gérez et investissez dans le secteur immobilier.",
    counter: "1 Formations",
    icon: "immobilier",
    url: "/formations",
  },
  {
    title: "Recouvrement",
    content: "Maîtrisez et Gérez efficacement des créances.",
    counter: "2 Formations",
    icon: "recouvrement",
    url: "/formations",
  },
  {
    title: "Vidéo & Graphisme",
    content: "Développez et Libérez votre créativité visuelle.",
    counter: "5 Formations",
    icon: "video",
    url: "/formations",
  },
  {
    title: "Prévention sécurité",
    content: "Assurez la sécurité au travail. (SST, HACCP, CACES)",
    counter: "10 Formations",
    icon: "sécurité",
    url: "/formations",
  },
  {
    title: "Langues",
    content: "Maîtrisez de nouvelles langues pour élargir vos horizons.",
    counter: "8 Formations",
    icon: "langue",
    url: "/formations",
  },
];


