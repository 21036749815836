import React from "react";
import cn from "classnames";
import styles from "./Receipt.module.sass";
import { Link } from "react-router-dom";

const Receipt = ({
  className,
  children,
  priceOld,
  priceActual,
  time,
  alternance
}) => {

  const isAlternanceAvailable = alternance === 1;
  const isNotAlternanceAvailable = alternance === 0;

  return (
    <>
    <div className={cn(className, styles.receipt)}>
      <div className={styles.head}>
        <div className={styles.details}>
          {isNotAlternanceAvailable && (
            <div className={styles.cost}>
              <div className={styles.old}>{priceOld}</div>
              <div className={styles.actual}>{priceActual}</div>
              <div className={styles.note}> (Net de taxe) /{time}</div>
            </div>
          )}
          {isAlternanceAvailable && (
            <div className={styles.cost}>
              <p>{priceActual}</p>
            </div>
          )}
        </div>
      </div>
      <div className={cn(styles.description)}>
        <div className={styles.item} >
          <Link className={cn("button", styles.button)} to="/contact">
            Contacter
          </Link>
        </div>
      </div>
      <div className={styles.body}>{children}</div>
    </div>
    </>
  );
};

export default Receipt;
