import React from "react";
import cn from 'classnames';
import Main from "./Main";
import styles from "./Cars.module.sass"



const Cars = () => {
  return (
    <>
      <Main />
      <div className={cn(styles.section)}>

        <div className="container">
          <h2>Préambule</h2>
          <br />
          <p>
            WAKE UP SUCCESS recherche la réussite et l’épanouissement personnel de ses
            stagiaires et de ses apprentis, désignés ci-dessous par le terme d’apprenant.<br />
            Elle doit être considérée par les apprenants comme une partie de l’Entreprise dans laquelle ils
            effectuent leur formation. Ils doivent donc évoluer dans notre établissement avec la même rigueur,
            tant par l’assiduité, la ponctualité, le travail fourni, que par le comportement vis à vis du personnel
            et du public fréquentant l’établissement.<br />
            Le présent règlement s’applique à l’ensemble des formations qui se déroulent sur les sites de
            formation relevant de l’autorité de WAKE UP SUCCESS. Il s’applique également aux formations à
            distance organisées sous la responsabilité de WAKE UP SUCCESS<br />
            Le présent règlement s’applique à tous les apprenant(e)s quel que soit leur statut, et ce pour la
            durée de la formation ou du stage suivi. Conformément aux articles L 6352-3, R 6352-1 et
            R 6231-5 du code dutravail, le présent règlement intérieur détermine :
          </p>
          <br />
          <ul>
            <li>1. Les principales mesures applicables en matière de santé et de sécurité au sein des sites de formation et autres locaux de WAKE UP SUCCESS</li>
            <li>2. Les règles relatives à la discipline, notamment la nature et l’échelle des sanctions ;</li>
            <li>3. Les modalités de représentation des apprenant(e)s ;</li>
            <li>4. Pour les CFA, les modalités de fonctionnement et de la désignation des membres du conseil de perfectionnement.</li>
          </ul>
          <br />
          <h2>ARTICLE 1 : HYGIÈNE, SANTÉ ET SÉCURITÉ</h2>
          <ul>
            <li><strong>1.1. Respect des règles d’hygiène, de santé et de sécurité</strong> <br></br>
              La prévention des risques d’accidents et de maladies est impérative et exige de chacun le respect
              total de toutes les prescriptions applicables en matière d’hygiène, de santé et de sécurité.
              A cet effet, les consignes générales et particulières de sécurité en vigueur dans le centre de
              formation, lorsqu’elles existent, doivent être strictement respectées sous peine de sanctions
              disciplinaires.
              En application de l’art R6352-1 du code du travail, lorsque la formation se déroule dans une
              entreprise ou dans un établissement déjà doté d’un règlement intérieur, les mesures de santé et
              de sécurité applicables aux apprenants sont celles de ce dernier règlement.</li>
            <li><strong>1.2. Propreté des locaux et des extérieurs</strong><br></br>
              Les locaux dans lesquels se déroulent les formations ainsi que les extérieurs sont régulièrement
              entretenus et nettoyés. Les apprenant(e)s</li>
            <li><strong>1.3. Mesures de prévention et/ou de sécurité</strong><br></br>
              Les consignes, en vigueur dans le centre de formation, à observer en cas de péril et spécialement
              d’incendie, doivent être scrupuleusement respectées ; le matériel de lutte contre l’incendie ne peut
              être employé à un autre usage,déplacé sans nécessité ou avoir son accès encombré.
              Toute manipulation abusive sera sanctionnée.
              En cas d’alerte incendie, il est obligatoire de cesser toute activité, de rejoindre les points
              d’évacuation et de se conformer aux instructions données par les chargés d’évacuation.</li>
            <li><strong>1.4. Accident</strong><br></br>
              Tout accident survenu en cours de formation ou à l’occasion du trajet entre le lieu de formation et
              le domicile ou le lieu de travail doit être immédiatement déclaré, par l’apprenant ou, le cas
              échéant, toute autre personne présente, au responsable de la formation ou au responsable de
              site.</li>
          </ul>
          <br />
          <h2>ARTICLE 2 : DISCIPLINE</h2>
          <ul>
            <li>
              <p><strong>2.1. Accès aux locaux</strong></p>
              <p>Les apprenants ayant accès aux locaux pour l’accomplissement de leur formation ou de leur
                stage ne peuvent :</p>
              <ul>
                <li>- Y entrer ou y demeurer à d’autres fins ;</li>
                <li>- Y entrer en état d’ébriété ou sous l’emprise de stupéfiants ;</li>
                <li>- Y introduire et y consommer des boissons alcoolisées et / ou toutes autres substances illicites ;</li>
                <li>- Y introduire, faire introduire ou faciliter l’introduction de personnes étrangères à la formation, ni de marchandises, quelle que soit leur nature, destinées à être vendues, ni d’armes ou d’objets dangereux.</li>
              </ul>
              <p>En cas de non-respect de ces dispositions, le centre de formation pourra envisager les sanctions
                visées à l’article 3.1 du présent règlement ainsi que des poursuites pénales.</p>
            </li>
            <li><br></br>
              <p><strong>2.2. Horaires - Assiduité - Retard - Absences</strong></p>
              <ul>
                <li><strong>Horaires : </strong>Les apprenants sont tenus de se conformer aux horaires fixés et communiqués par le
                  centre de formation de WAKE UP SUCCESS.Le non-respect de ces horaires peut
                  entrainer une des sanctions visées à l’article 3.1 du présent règlement.</li><br></br>
                <li><strong>Assiduité :</strong> Les apprenants ont pour obligation de suivre la formation avec assiduité. Toute heure
                  ou demi-journée de formation donnera lieu à émargement. Un manquement de signature équivaut
                  à une absence.
                  Il est formellement interdit de signer pour un autre apprenant et de falsifier ou de dégrader, de
                  quelque manière que ce soit, ladite feuille d’émargement. Le non-respect de ces dispositions peut
                  entrainer une des sanctions visées à l’article 3.1 du présent règlement.
                  Sauf circonstance exceptionnelle, les apprenants ne peuvent s’absenter pendant les heures de
                  formation.</li><br></br>
                <li><strong>Retard : </strong>Tout apprenant arrivant en retard doit exposer les motifs de son retard au responsable
                  de la formation, du stage ou aux services concernés. Tout retard, non motivé par des
                  circonstances particulières, constitue une faute passible des sanctions prévues à l’article 3.1 du
                  présent règlement.</li><br></br>
                <li><strong>Absence :</strong> Toute absence doit être signalée dans les meilleurs délais et au plus tard le jour
                  même, par l’apprenant, au site de formation ou au responsable de la formation. Les absences
                  doivent faire l’objet d’un justificatif écrit. Les seules absences valables et justifiées sont celles
                  motivées :
                  • Par la maladie, qui nécessite la transmission, par l’apprenant, au centre de formation et, le
                  cas échéant à son employeur, d’un arrêt de travail (pour les apprentis, les salariés, les
                  personnes inscrites à Pôle Emploi...)
                  • Par des circonstances familiales exceptionnelles, en application de l’article L3142-1 du Code du
                  travail ;
                  • Par la participation à la Journée Défense et Citoyenneté, en application de l’article
                  L3142-97 du Code du travail.<br></br>
                  Le centre de formation informe l’employeur, et le cas échéant, les représentants légaux, de toute
                  absence de l’apprenant.
                  Pour les apprentis, une absence injustifiée peut donner lieu à une retenue sur leur rémunération.
                  En cas d’absences répétées et non-justifiées, le centre de formation pourra envisager les
                  sanctions visées à l’article 3.1 du présent règlement.</li>
              </ul>
            </li><br></br>
            <li>
              <strong>2.3. Tenue - Port de signes ou de tenues ostentatoires</strong>
              Les apprenant(e)s doivent se présenter dans une tenue correcte et décente. Cette dernière doit
              être adaptée à la situation.
              Des restrictions à la liberté vestimentaire peuvent, toutefois, être exigées pour des motifs tenant
              notamment à l’hygiène, à la sécurité et au respect de l’ordre public.
              Lorsque les stages ou les formations se déroulent dans un centre de formation soumis aux
              dispositions de l’article L141-5-1 du Code de l’éducation interdisant le port de signes religieux
              ostensibles, les apprenants doivent se conformer à cette obligation.
              Tout différend en la matière fera, au préalable, avant toute sanction, l’objet d’un dialogue avec
              l’apprenant concerné.
            </li><br></br>
            <li><strong>2.4. Tenue professionnelle - Matériel professionnel</strong>Si les spécificités de la formation suivie l’exigent, les apprenants doivent être en possession de la
              tenue et/ou du matériel nécessaire pour suivre ladite formation et, le cas échéant, en conformité
              avec la liste élaborée par le centre de formation ainsi qu’avec les règlementations sanitaires ou de
              sécurité en vigueur.
              Les apprenants doivent également respecter les consignes affichées à l’entrée de chaque plateau
              technique et les directives données par le professeur et/ou formateur.
              Les apprenants n’ayant pas leur tenue professionnelle et/ou leur matériel professionnel se verront
              refuser l’accès aux ateliers/plateaux techniques ou laboratoires.
              L’oubli de la tenue professionnelle et/ou du matériel sera sanctionné.
              Les apprenants sont tenus de conserver en bon état les équipements et matériels mis à leur
              disposition.</li><br></br>
            <li><strong>2.5. Usage des biens, des matériels et des locaux</strong>
              <ul>
                <li>- Distribuer ou faire circuler toute espèce de brochures, publications, listes de souscription... ;</li><br></br>
                <li>- Apposer des inscriptions, tracts, affiches ;</li>
                <li>- Procéder à la vente de biens ou services ;</li>
                <li>- Utiliser le matériel en l’absence d’un enseignant ;</li>
                <li>- Utiliser le matériel de formation à des fins personnelles ;</li>
                <li>- Emporter, sans accord, les outils, les objets ou les « produits » (de type produits alimentaires) de la formation.</li><br></br>
              </ul>
              <p>Les apprenants signalent immédiatement au responsable de la formation toute anomalie du matériel.</p>

            </li><br></br>
            <li><strong>2.6. Usage des téléphones, tablettes et ordinateurs</strong> Les téléphones, les tablettes et les ordinateurs des apprenants doivent être rangés en mode
              silencieux pendant les cours sauf autorisation d’utilisation donnée par l'intervenant. </li><br></br>
            <li><strong>2.7. Comportement - Respect d’autrui</strong> Les apprenants sont tenus d’adopter un comportement garantissant le respect des règles
              élémentaires de savoir-vivre et de savoir-être en collectivité ainsi que le bon déroulement des
              formations.
              Les apprenants sont tenus d’adopter un comportement tenant compte du devoir de tolérance et de
              respect d’autrui.
              Ils ne doivent en aucun cas être violent physiquement, moralement ou verbalement.
              Les sanctions prévues à l’article 3.1 du présent règlement pourront être appliquées.
              Les attitudes de harcèlement, de discrimination ou sexistes au sens de la loi sont passibles d’une
              sanction disciplinaire.</li><br></br>
            <li><strong>2.8. Interdiction de fumer et de vapoter</strong> En application du décret du 15 novembre 2006 fixant les conditions d’application de l’interdiction
              de fumer dans les lieux affectés à un usage collectif, il est totalement interdit de fumer dans
              l’enceinte (locaux et espaces non couverts) du centre de formation.
              Cette interdiction s’applique également à la cigarette électronique, en application du décret du 25
              avril 2017 relatif aux conditions d’application de l’interdiction de vapoter dans certains lieux à
              usage collectif.</li><br></br>
            <li><strong>2.9. Sorties</strong>En dehors du temps consacré à la formation, les sorties du centre de formation sont autorisées.
              Les apprenants mineurs devront produire, en début d’année, une autorisation de sortie signée par
              leur représentant légal. Ils ne pourront sortir du centre de formation sans cette autorisation.

              L’apprenant mineur qui sort du centre de formation alors même qu’il/elle est dépourvu
              d’autorisation de sortie pourra se voir appliquer les sanctions prévues à l’article 3.1 du présent
              règlement.
              Lorsque l’apprenant, même mineur se trouve à l’extérieur du centre de formation, le centre de
              formation et, de manière générale, WAKE UP SUCESS se dégage de toute responsabilité.</li><br></br>
            <li><strong>2.10. Restauration </strong>  Il est strictement interdit de boire et/ou de manger dans les salles affectées à la formation ou au
              stage.
              Toutefois, la consommation d’eau est autorisée. Celle-ci est strictement interdite à proximité du
              matériel informatique et de tout autre matériel électrique.
              Pour les centres de formation équipés d’un self, des dispositions particulières propres à chaque site
              complètent le présent règlement.
              Les apprenants ont accès aux distributeurs automatiques de boissons chaudes et fraiches et de
              denrées alimentaires lors des pauses fixées.</li><br></br>
            <li><strong>2.11. Responsabilité du centre de formation ou de l’établissement</strong> Les apprenants sont responsables de leurs affaires personnelles.
              Le centre de formation décline toute responsabilité en cas de perte, de vol ou de dégradations
              d’objets ou de valeurs appartenant aux apprenants, ainsi que pour les véhicules en
              stationnement sur les parkings intérieurs lorsqu’ils existent.</li><br></br>
            <li><strong>2.12. Droit à l’image</strong> Toute personne a, sur son image et l’utilisation qui en est faite, un droit exclusif et peut
              s’opposer à sa diffusion sans son autorisation.
              Il est interdit de photographier, d’enregistrer, de filmer ou, de manière générale, d’acquérir
              l’image d’une personne, dans le centre de formation, quel que soit le moyen utilisé, sans le
              consentement écrit de celle-ci.
              Tout écart au présent article entraînera des sanctions.</li><br></br>
          </ul>
          <br />


          <h2>ARTICLE 3 : SANCTIONS, PROCÉDURES DISCIPLINAIRES ET DROITS DE LA DÉFENSE</h2>
          <p><strong>3.1. Sanctions éventuelles</strong></p>
          <p>Tout agissement fautif d’un apprenant, en particulier tout acte de nature à porter atteinte à la sécurité ou à troubler le bon déroulement de la formation et, spécialement, toute infraction au présent règlement et à ses annexes en vigueur de manière générale, peut faire l’objet d’une sanction pouvant éventuellement affecter, immédiatement ou non, sa présence dans le centre de formation.</p>
          <p>Constitue une sanction, toute mesure, autre que les observations verbales, prise par le responsable du centre de formation ou son représentant à la suite d’un agissement de l’apprenant qualifié comme fautif, que cette mesure soit de nature à affecter immédiatement ou non la présence de l’intéressé dans le centre de formation ou à mettre en question la continuité de la formation qu’il reçoit.</p>
          <p>Une échelle de sanctions sous forme de « permis à points » peut être mise en place par certains sites de formation (se référer à l’annexe du département de l’apprenant).</p>
          <p>Selon la nature et les circonstances de l’agissement ou de l’acte, dans le strict respect des dispositions légales et règlementaires en vigueur, la sanction susceptible d’être appliquée par le centre de formation à l’apprenant sera l’une des sanctions ou mesures suivantes par ordre d’importance :</p>
          <ol>
            <li>Rappel à l’ordre ;</li>
            <li>Avertissement écrit, attirant l’attention de l’apprenant ;</li>
            <li>Mise à pied disciplinaire d’une durée maximum de 7 jours ;</li>
            <li>Renvoi définitif.</li>
          </ol>
          <p>Il convient de préciser que, pour les apprenants relevant du Centre de Formation d’Apprentis, des mesures éducatives (inscription dans le livret, travaux supplémentaires...) pourront éventuellement être prises, préalablement à toute sanction, en fonction de la gravité du comportement sanctionné.</p><br></br>
          <p><strong>3.2. Procédures disciplinaires</strong></p>

          <p>Aucune sanction ne peut être infligée à l’apprenant sans que celui-ci ait été informé, au préalable, des griefs retenus contre lui.</p>
          <p>Lorsque la sanction envisagée est de nature à affecter, immédiatement ou non, la présence de l’apprenant dans le centre de formation, la procédure à suivre est la suivante :</p>
          <ul>
            <li>Convocation de l’apprenant : le responsable du centre de formation ou de son représentant convoque l’apprenant en lui indiquant l’objet de cette convocation. Celle-ci précise la date, l’heure et le lieu de l’entretien ainsi que la possibilité, pour l’apprenant, de se faire assister par la personne de son choix (notamment le délégué, un stagiaire, tout autre apprenant ou personnel du centre de formation...). Elle est écrite et adressée par lettre recommandée ou remise à l’intéressé en mains propres contre décharge.</li>
            <li>Entretien : le responsable du centre de formation ou de son représentant indique le motif de la sanction envisagée et recueille les explications de l’apprenant.</li>
            <li>Prononcé de la sanction : la sanction ne peut être prononcée moins d’un jour franc ni plus de quinze (15) jours après l’entretien. Elle fait l’objet d’une décision écrite et motivée, portée à la connaissance de l’apprenant par lettre recommandée ou remise en mains propres contre décharge.</li>
          </ul>
          <p>Il convient de préciser que pour les apprenants relevant du Centre de Formation d’Apprentis, l’exclusion définitive de l’apprenant relève de la seule décision du conseil de discipline.</p>
          <p>Le conseil de discipline est composé des membres suivants :</p>
          <ul>
            <li>Le responsable du centre de formation ou son représentant ;</li>
            <li>Un ou des représentants de l’équipe pédagogique.</li>
          </ul>
          <p>Lors de la réunion du conseil de discipline, sont convoqués :</p>
          <ul>
            <li>L’apprenti ;</li>
            <li>Le cas échéant, son représentant légal ;</li>
            <li>L’employeur.</li>
          </ul> <br></br>

          <p><strong>3.3. Information</strong></p>
          <p>Concomitamment à la convocation de l’apprenant, son employeur est informé de la procédure
            disciplinaire, de son objet et du motif de la sanction envisagée.

            Par ailleurs, le responsable du centre de formation informe l’employeur et le cas échéant, les
            représentants légaux, de la sanction prise à l’égard de l’apprenant.</p>
          <br />
          <h2>ARTICLE 4 : REPRÉSENTATION</h2>
          <ul>
            <li>Pour les formations d’une durée totale supérieure à 500 heures (article R6352-9, code du travail, décret du 7 novembre 2019 article 4), il est procédé simultanément à l’élection d’un délégué titulaire et d’un délégué suppléant au scrutin uninominal à deux tours.</li>
            <li>Tous les apprenants sont électeurs et éligibles.</li>
            <li>Le scrutin se déroule pendant les heures de formation, au plus tôt 20 heures et au plus tard 40 heures après le début de la formation.</li>
            <li>Lorsque, à l’issue du scrutin, il est constaté que la représentation des apprenants ne peut être assurée, le responsable du centre de formation ou son représentant dresse un procès-verbal de carence.</li>
            <li>Les délégués sont élus pour la durée de la formation. Leurs fonctions prennent fin lorsqu’ils cessent de participer à la formation.</li>
            <li>Lorsque le délégué titulaire et le délégué suppléant ont cessé leurs fonctions avant la fin de la formation, il est procédé à une nouvelle élection, dans les conditions précitées.</li>
            <li>Les délégués font toute suggestion pour améliorer le déroulement des formations et les conditions de vie des apprenants dans le centre de formation. Ils présentent les réclamations individuelles ou collectives relatives à ces matières, aux conditions de santé et de sécurité et à l’application du règlement intérieur.</li>
          </ul>

          <br />
          <h2>ARTICLE 5 : CONSEIL DE PERFECTIONNEMENT</h2>
          <p>Conformément à l’article L6231-3 du Code du travail, il est institué un conseil de
            perfectionnement pour veiller à l’organisation et au fonctionnement du CFA.</p><br></br>
          <p><strong>5.1. Modalités de désignation du Conseil de perfectionnement</strong></p>
          <p>5.2. Conformément à l’article R6231-5 du Code du Travail, la présidence du conseil de perfectionnement est assurée par le directeur de l'organisme de formation délivrant des formations par apprentissage ou son représentant. Le Conseil de perfectionnement est composé :</p>
          <ul>
            <li>- De représentants de l’organisme gestionnaire du CFA régional, à savoir : le Président de WAKE UP SUCCESS, ainsi que le directeur de l’établissement ;</li>
            <li>- Des représentants des personnels d’enseignement et d’encadrement ;</li>
            <li>- Des représentants des apprentis désignés parmi les délégués ;</li>
            <li>- Du directeur régional de la formation ou son représentant ;</li>
            <li>- Des directeurs de sites de formation ;</li>
            <li>- Du référent Qualité ;</li>
            <li>- Du référent handicap ;</li>
            <li>- Du responsable des projets transversaux.</li>
          </ul>
          <p>A titre consultatif, peuvent être invités :</p>
          <ul>
            <li>- Un représentant du Conseil Régional ;</li>
            <li>- Un représentant du Rectorat ;</li>
            <li>- Un représentant de la DRIEETS ;</li>
            <li>- Des représentants désignés au sein des OPCO ;</li>
            <li>- Des personnes qualifiées en raison de leur expérience pédagogique et/ou professionnelle.</li>
          </ul><br></br>
          <p><strong>5.3. Fonctionnement du Conseil de perfectionnement</strong></p>
          <p>Le Conseil de perfectionnement est régional. Il se réunit au moins deux fois par an, sur convocation du Président, qui arrête l’ordre du jour.</p>
          <p>Le Conseil de perfectionnement peut se réunir en visioconférence.</p>
          <ul>
            <li>Les convocations sont envoyées aux membres 7 jours au plus tard avant la date du Conseil de perfectionnement.</li>
            <li>Les documents préparatoires à l’ordre du jour seront joints, selon les besoins, à la convocation ou envoyés 5 jours au plus tard avant la date du Conseil de perfectionnement. Des documents complémentaires peuvent être présentés le jour même de la réunion.</li>
          </ul>
          <p>Le Conseil de perfectionnement rend ses avis à la majorité des membres présents ou représentés.</p>
          <ul>
            <li>Le vote s’effectue à main levée. En cas de partage des voix, la voix du Président est prépondérante.</li>
            <li>Le directeur régional de la formation ou son représentant assure la diffusion des comptes rendus des séances du Conseil de perfectionnement à l’ensemble de ses membres.</li>
          </ul>
          <p>A chaque conseil de perfectionnement peuvent être associés des réunions ou conseils au niveau départemental, organisés dans chacun des sites de formation de l’organisme de formation régional qui accueille des apprentis. Ces réunions ou conseils sont présidés par le directeur du site ou son représentant.</p>
          <p>Leur composition doit permettre de débattre les problématiques liées au site et au fonctionnement quotidien. Le Président de WAKE UP SUCCESS</p><br></br>
          <p><strong>5.4. Attributions du Conseil de perfectionnement</strong></p>
          <p>Le Conseil de perfectionnement examine et débat des questions relatives à l’organisation et à la stratégie générale du CFA de région :</p>
          <ul>
            <li>Le projet pédagogique du centre de formation d'apprentis ;</li>
            <li>Les conditions générales d'accueil, d'accompagnement des apprentis, notamment des apprentis en situation de handicap, de promotion de la mixité et de la mobilité nationale et internationale ;</li>
            <li>Les projets d'investissement ;</li>
            <li>Les informations publiées chaque année en application de l’article L. 6111-8 du Code du Travail :
              <ol>
                <li>- Le taux d'obtention des diplômes ou titres professionnels ;</li>
                <li>- Le taux de poursuite d'études ;</li>
                <li>- Le taux d'interruption en cours de formation ;</li>
                <li>- Le taux d'insertion professionnelle des sortants de l'établissement concerné, à la suite des formations dispensées ;</li>
                <li>- La valeur ajoutée de l'établissement.</li>
              </ol>
            </li>
          </ul>
          <br />
          <h2>Article 6. Assurance</h2>
          <p>Pendant toute la durée de sa formation, l’apprenant, ou son représentant légal s’il est mineur, doit
            avoir souscrit une assurance garantissant sa responsabilité civile.</p>
          <br />
          <h2>Article 7. Dispositions diverses</h2>
          <p><strong>7.1. Précisions complémentaires</strong>
            En raison de la diversité des statuts des apprenants et des sites, les dispositions du présent
            règlement sont d’ordre général. Il peut être complété par des dispositions particulières qui seront
            portées à la connaissance des apprenants.</p><br></br>
          <p><strong>7.2. Protection et traitement des données personnelles</strong>
            WAKE UP SUCCESS est amenée à recueillir et traiter des informations personnelles relatives aux
            apprenants pour les besoins de son service formation.
            Les apprenants sont informés que les données personnelles qu’ils communiquent à WAKE
            UP SUCCESS pourront être communiquées aux prestataires extérieurs auxquels elle fait
            appel.
            La durée de conservation des données est de 5 ans.
            L’apprenant bénéficie notamment d’un droit d’accès, de rectification, d’effacement, d’opposition,
            de limitation des informations le concernant qu’il peut exercer en s’adressant au délégué à la
            protection des données de WAKE UP SUCCESS en lui adressant email à l’adresse suivante :
            rtebboub@wake-up-success.fr peut également porter réclamation auprès de la
            Commission Nationale de l’Informatique et des Libertés : la CNIL - 3 place de Fontenoy - TSA
            80715 - 75334 PARIS CEDEX 07.</p>
          <br />
          <h2>Article 8. Publicité</h2>
          <p>Le présent règlement est porté à la connaissance de l’apprenant et, le cas échéant, de son
            représentant légal et de son employeur par plusieurs moyens de communication (site Internet,
            affichages, courriels...) à son entrée en formation ou stage.</p><br></br>
          <p>Le présent règlement intérieur entre en vigueur à partir de février 2024</p>
          <p>Actualisé le : 16/02/2024</p>
        </div>
      </div>
    </>
  );
};

export default Cars;
