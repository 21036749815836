import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Image from "../Image";

const Header = ({ separatorHeader, wide }) => {
    const [visibleNav, setVisibleNav] = useState(false);
    const { pathname } = useLocation();

    const closeMenu = () => {
        setVisibleNav(false);
    };

    return (
        <>
            <div className={cn(styles.header, { [styles.headerBorder]: separatorHeader }, { [styles.wide]: wide })}>
                <div className={cn("container", styles.container)}>
                    <Link className={styles.logo} to="/" onClick={closeMenu}>
                        <Image
                            className={styles.pic}
                            src="/images/Wake Up Success.webp"
                            srcDark="/images/Wake Up Success.webp"
                            alt="Fleet"
                        />
                    </Link>
                    <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
                        <NavLink
                            className={cn(styles.link, { [styles.active]: pathname === "/formations" })}
                            to="/"
                            onClick={closeMenu}
                        >
                            Accueil
                        </NavLink>
                        <NavLink
                            className={cn(styles.link, { [styles.active]: pathname === "/formations" })}
                            to="/formations"
                            onClick={closeMenu}
                        >
                            Nos formations
                        </NavLink>
                        <NavLink
                            className={cn(styles.link, { [styles.active]: pathname === "/about" })}
                            to="/about"
                            onClick={closeMenu}
                        >
                            En savoir plus
                        </NavLink>
                        <NavLink
                            className={cn("button-stroke button-small", styles.button)}
                            to="/contact"
                            onClick={closeMenu}
                        >
                            Contacter
                        </NavLink>
                    </div>
                    <button
                        className={cn(styles.burger, { [styles.active]: visibleNav })}
                        onClick={() => setVisibleNav(!visibleNav)}
                    ></button>
                </div>
            </div>

        </>
    );
};

export default Header;
