export const gallery = [
  {
    id: 'Les-fondamentaux-du-management',
    gallery: [
      "/images/Les fondamentaux du management.png",
      "/images/Les fondamentaux du management1.png",
      "/images/Les fondamentaux du management2.png",
      "/images/Les fondamentaux du management2.png",
      "/images/Les fondamentaux du management4.png",
    ],
  },
  {
    id: 'Management-d-équipe',
    gallery: [
      "/images/Management d’ équipe.png",
      "/images/Management d’ équipe1.png",
      "/images/Management d’ équipe2.png",
      "/images/Management d’ équipe3.png",
      "/images/Management d’ équipe4.png",
    ],
  },
  {
    id: 'Négociateur-technico-commercial',
    gallery: [
      "/images/Alternance - Négociateur technico commercial.png",
      "/images/Alternance - Négociateur technico commercial 1.png",
      "/images/Alternance - Négociateur technico commercial 2.png",
      "/images/Alternance - Négociateur technico commercial 3.png",
      "/images/Alternance - Négociateur technico commercial 4.png",
    ],
  },
  {
    id: 'Gestion-des-conflits',
    gallery: [
      "/images/Gestion des conflits.png",
      "/images/Gestion des conflits1.png",
      "/images/Gestion des conflits2.png",
      "/images/Gestion des conflits3.png",
      "/images/Gestion des conflits4.png",
    ],
  },
  {
    id: 'Gestion-de-projet',
    gallery: [
      "/images/Gestion de projet.png",
      "/images/Gestion de projet1.png",
      "/images/Gestion de projet2.png",
      "/images/Gestion de projet3.png",
      "/images/Gestion de projet4.png",
    ],
  },
  {
    id: 'Management-et-politique-publique',
    gallery: [
      "/images/Management et politique publique.png",
      "/images/Management et politique publique1.png",
      "/images/Management et politique publique2.png",
      "/images/Management et politique publique3.png",
      "/images/Management et politique publique4.png",
    ],
  },
  {
    id: 'Management-d-équipe-dans-un-contexte-de-changement',
    gallery: [
      "/images/Management d’équipe dans un contexte de changement.png",
      "/images/Management d’équipe dans un contexte de changement1.png",
      "/images/Management d’équipe dans un contexte de changement2.png",
      "/images/Management d’équipe dans un contexte de changement3.png",
      "/images/Management d’équipe dans un contexte de changement4.png",
    ],
  },
  {
    id: 'Communication-de-crises',
    gallery: [
      "/images/Communication de crises.png",
      "/images/Communication de crises1.png",
      "/images/Communication de crises2.png",
      "/images/Communication de crises3.png",
      "/images/Communication de crises4.png",
    ],
  },
  {
    id: 'Parole-en-public',
    gallery: [
      "/images/Parole en public.png",
      "/images/Parole en public1.png",
      "/images/Parole en public2.png",
      "/images/Parole en public3.png",
      "/images/Parole en public4.png",
    ],
  },
  {
    id: 'Introduction-au-Marketing-digital',
    gallery: [
      "/images/Introduction au Marketing digital.png",
      "/images/Introduction au Marketing digital1.png",
      "/images/Introduction au Marketing digital2.png",
      "/images/Introduction au Marketing digital3.png",
      "/images/Introduction au Marketing digital4.png",
    ],
  },
  {
    id: 'Marketing-Digital-et-Growth-Hacking',
    gallery: [
      "/images/Marketing Digital et Growth Hacking.png",
      "/images/Marketing Digital et Growth Hacking1.png",
      "/images/Marketing Digital et Growth Hacking2.png",
      "/images/Marketing Digital et Growth Hacking3.png",
      "/images/Marketing Digital et Growth Hacking4.png",
    ],
  },
  {
    id: 'Stratégie-de-Webmarketing',
    gallery: [
      "/images/Stratégie de Webmarketing.png",
      "/images/Stratégie de Webmarketing1.png",
      "/images/Stratégie de Webmarketing2.png",
      "/images/Stratégie de Webmarketing3.png",
      "/images/Stratégie de Webmarketing4.png",
    ],
  },
  {
    id: 'Compétences-digitales-et-Digcomp',
    gallery: [
      "/images/Compétences digitales et Digcomp.png",
      "/images/Compétences digitales et Digcomp1.png",
      "/images/Compétences digitales et Digcomp2.png",
      "/images/Compétences digitales et Digcomp3.png",
      "/images/Compétences digitales et Digcomp4.png",
    ],
  },
  {
    id: 'Créer-et-animer-un-site-WEB',
    gallery: [
      "/images/Créer et animer un site WEB.png",
      "/images/Créer et animer un site WEB1.png",
      "/images/Créer et animer un site WEB2.png",
      "/images/Créer et animer un site WEB3.png",
      "/images/Créer et animer un site WEB4.png",
    ],
  },
  {
    id: 'Référencement-naturel-SEO',
    gallery: [
      "/images/Référencement naturel SEO.png",
      "/images/Référencement naturel SEO1.png",
      "/images/Référencement naturel SEO2.png",
      "/images/Référencement naturel SEO3.png",
      "/images/Référencement naturel SEO4.png",
    ],
  },
  {
    id: 'Créer-une-stratégie-de-communication-avec-les-Réseaux-et-ADS',
    gallery: [
      "/images/Créer une stratégie de communication avec les Réseaux et ADS.png",
      "/images/Créer une stratégie de communication avec les Réseaux et ADS1.png",
      "/images/Créer une stratégie de communication avec les Réseaux et ADS2.png",
      "/images/Créer une stratégie de communication avec les Réseaux et ADS3.png",
      "/images/Créer une stratégie de communication avec les Réseaux et ADS4.png",
    ],
  },
  {
    id: 'Prévention-Geste-&-Posture-au-travail',
    gallery: [
      "/images/Prévention Geste & Posture au travail.png",
      "/images/Prévention Geste & Posture au travail1.png",
      "/images/Prévention Geste & Posture au travail2.png",
      "/images/Prévention Geste & Posture au travail3.png",
      "/images/Prévention Geste & Posture au travail4.png",
    ],
  },
  {
    id: 'Qualité-de-vie-au-travail',
    gallery: [
      "/images/Qualité de vie au travail.png",
      "/images/Qualité de vie au travail1.png",
      "/images/Qualité de vie au travail2.png",
      "/images/Qualité de vie au travail3.png",
      "/images/Qualité de vie au travail4.png",
    ],
  },
  {
    id: 'Gestion-du-stress',
    gallery: [
      "/images/Gestion du stress.png",
      "/images/Gestion du stress1.png",
      "/images/Gestion du stress2.png",
      "/images/Gestion du stress3.png",
      "/images/Gestion du stress4.png",
    ],
  },
  {
    id: 'Sauveteur-Secouriste-du-travail',
    gallery: [
      "/images/Sauveteur Secouriste du travail ( SST ).png",
      "/images/Sauveteur Secouriste du travail ( SST )1.png",
      "/images/Sauveteur Secouriste du travail ( SST )2.png",
      "/images/Sauveteur Secouriste du travail ( SST )3.png",
      "/images/Sauveteur Secouriste du travail ( SST )4.png",
    ],
  },
  {
    id: 'Incendie-en-entreprise',
    gallery: [
      "/images/Incendie en entreprise.png",
      "/images/Incendie en entreprise1.png",
      "/images/Incendie en entreprise2.png",
      "/images/Incendie en entreprise3.png",
      "/images/Incendie en entreprise4.png",
    ],
  },
  {
    id: 'HACCP-Hygiène-Alimentaire',
    gallery: [
      "/images/HACCP Hygiène Alimentaire.png",
      "/images/HACCP Hygiène Alimentaire1.png",
      "/images/HACCP Hygiène Alimentaire2.png",
      "/images/HACCP Hygiène Alimentaire3.png",
      "/images/HACCP Hygiène Alimentaire4.png",
    ],
  },
  {
    id: 'Habilitation-électrique-Photovoltaïque',
    gallery: [
      "/images/Habilitation électrique Photovoltaïque.png",
      "/images/Habilitation électrique Photovoltaïque1.png",
      "/images/Habilitation électrique Photovoltaïque2.png",
      "/images/Habilitation électrique Photovoltaïque3.png",
      "/images/Habilitation électrique Photovoltaïque4.png",
    ],
  },
  {
    id: 'Habilitation-électrique-Photovoltaïque-BP-BR',
    gallery: [
      "/images/Habilitation électrique Photovoltaïque BP BR.png",
      "/images/Habilitation électrique Photovoltaïque BP BR1.png",
      "/images/Habilitation électrique Photovoltaïque BP BR2.png",
      "/images/Habilitation électrique Photovoltaïque BP BR3.png",
      "/images/Habilitation électrique Photovoltaïque BP BR4.png",
    ],
  },
  {
    id: 'Habilitation-électrique-BE-manœuvre',
    gallery: [
      "/images/Habilitation-électrique-BE-manœuvre.png",
      "/images/Habilitation-électrique-BE-manœuvre1.png",
      "/images/Habilitation-électrique-BE-manœuvre2.png",
      "/images/Habilitation-électrique-BE-manœuvre3.png",
      "/images/Habilitation-électrique-BE-manœuvre4.png",
    ],
  },
  {
    id: 'Préparation-CACES',
    gallery: [
      "/images/Préparation CACES Gerbeur R 485.png",
      "/images/Préparation CACES Gerbeur R 4851.png",
      "/images/Préparation CACES Gerbeur R 4852.png",
      "/images/Préparation CACES Gerbeur R 4853.png",
      "/images/Préparation CACES Gerbeur R 4854.png",
    ],
  },
  {
    id: 'Créer-son-entreprise-de-A-à-Z',
    gallery: [
      "/images/Créer son entreprise de A à Z.png",
      "/images/Créer son entreprise de A à Z1.png",
      "/images/Créer son entreprise de A à Z2.png",
      "/images/Créer son entreprise de A à Z3.png",
      "/images/Créer son entreprise de A à Z4.png",
    ],
  },
  {
    id: 'Document-unique-obligatoire',
    gallery: [
      "/images/Document unique obligatoire.png",
      "/images/Document unique obligatoire1.png",
      "/images/Document unique obligatoire2.png",
      "/images/Document unique obligatoire3.png",
      "/images/Document unique obligatoire4.png",
    ],
  },
  {
    id: 'Formation-CSE',
    gallery: [
      "/images/Formation cse.png",
      "/images/Formation cse1.png",
      "/images/Formation cse2.png",
      "/images/Formation cse3.png",
      "/images/Formation cse4.png",
    ],
  },
  {
    id: 'Devenir-Formateur',
    gallery: [
      "/images/Devenir formateur.png",
      "/images/Devenir formateur1.png",
      "/images/Devenir formateur2.png",
      "/images/Devenir formateur3.png",
      "/images/Devenir formateur4.png",
    ],
  },
  {
    id: 'Conseiller-de-vente-en-magasin-et-à-distance',
    gallery: [
      "/images/Conseiller de vente en magasin et à distance.png",
      "/images/Conseiller de vente en magasin et à distance1.png",
      "/images/Conseiller de vente en magasin et à distance2.png",
      "/images/Conseiller de vente en magasin et à distance3.png",
      "/images/Conseiller de vente en magasin et à distance4.png",
    ],
  },
  {
    id: 'Technique-de-ventes-omnicanal',
    gallery: [
      "/images/Technique de ventes omnicanal.png",
      "/images/Technique de ventes omnicanal1.png",
      "/images/Technique de ventes omnicanal2.png",
      "/images/Technique de ventes omnicanal3.png",
      "/images/Technique de ventes omnicanal4.png",
    ],
  },
  {
    id: 'Développer-l-approche-commercial',
    gallery: [
      "/images/Développer l’approche commercial.png",
      "/images/Développer l’approche commercial1.png",
      "/images/Développer l’approche commercial2.png",
      "/images/Développer l’approche commercial3.png",
      "/images/Développer l’approche commercial4.png",
    ],
  },
  {
    id: 'Formation-négociation-commerciale-Grands-Comptes',
    gallery: [
      "/images/Formation négociation commerciale Grands Comptes.png",
      "/images/Formation négociation commerciale Grands Comptes1.png",
      "/images/Formation négociation commerciale Grands Comptes2.png",
      "/images/Formation négociation commerciale Grands Comptes3.png",
      "/images/Formation négociation commerciale Grands Comptes4.png",
    ],
  },
  {
    id: 'Formation-Recouvrement-de-créances',
    gallery: [
      "/images/Formation Recouvrement de créances.png",
      "/images/Formation Recouvrement de créances1.png",
      "/images/Formation Recouvrement de créances2.png",
      "/images/Formation Recouvrement de créances3.png",
      "/images/Formation Recouvrement de créances4.png",
    ],
  },
  {
    id: 'Recouvrement-de-créances-amiable-&-contentieux',
    gallery: [
      "/images/Recouvrement de créances amiable & contentieux.png",
      "/images/Recouvrement de créances amiable & contentieux1.png",
      "/images/Recouvrement de créances amiable & contentieux2.png",
      "/images/Recouvrement de créances amiable & contentieux3.png",
      "/images/Recouvrement de créances amiable & contentieux4.png",
    ],
  },
  {
    id: 'Conception-des-vidéos-Motion-Design',
    gallery: [
      "/images/Conception des vidéos Motion Design.png",
      "/images/Conception des vidéos Motion Design1.png",
      "/images/Conception des vidéos Motion Design2.png",
      "/images/Conception des vidéos Motion Design3.png",
      "/images/Conception des vidéos Motion Design4.png",
    ],
  },
  {
    id: 'Edition-de-site-web',
    gallery: [
      "/images/Edition de site web.png",
      "/images/Edition de site web1.png",
      "/images/Edition de site web2.png",
      "/images/Edition de site web3.png",
      "/images/Edition de site web4.png",
    ],
  },
  {
    id: 'Pack-Adobe-tous-niveaux',
    gallery: [
      "/images/Pack Adobe tous niveaux.png",
      "/images/Pack Adobe tous niveaux1.png",
      "/images/Pack Adobe tous niveaux2.png",
      "/images/Pack Adobe tous niveaux3.png",
      "/images/Pack Adobe tous niveaux4.png",
    ],
  },
  {
    id: 'Rédacteur-WEB-et-IA',
    gallery: [
      "/images/Rédacteur WEB et IA.png",
      "/images/Rédacteur WEB et IA1.png",
      "/images/Rédacteur WEB et IA2.png",
      "/images/Rédacteur WEB et IA3.png",
      "/images/Rédacteur WEB et IA4.png",
    ],
  },
  {
    id: 'SKETCHUP-PRO',
    gallery: [
      "/images/SKETCHUP PRO.png",
      "/images/SKETCHUP PRO1.png",
      "/images/SKETCHUP PRO2.png",
      "/images/SKETCHUP PRO3.png",
      "/images/SKETCHUP PRO4.png",
    ],
  },
  {
    id: 'Pack-application-bureautique-Essentiel',
    gallery: [
      "/images/Pack application bureautique Essentiel.png",
      "/images/Pack application bureautique Essentiel1.png",
      "/images/Pack application bureautique Essentiel2.png",
      "/images/Pack application bureautique Essentiel3.png",
      "/images/Pack application bureautique Essentiel4.png",
    ],
  },
  {
    id: 'Plateforme-collaborative-Microsoft-Office-365',
    gallery: [
      "/images/Plateforme collaborative Microsoft Office 365.png",
      "/images/Plateforme collaborative Microsoft Office 3651.png",
      "/images/Plateforme collaborative Microsoft Office 3652.png",
      "/images/Plateforme collaborative Microsoft Office 3653.png",
      "/images/Plateforme collaborative Microsoft Office 3654.png",
    ],
  },
  {
    id: 'Maitriser-le-langage-Python',
    gallery: [
      "/images/Maitriser le langage Python.png",
      "/images/Maitriser le langage Python1.png",
      "/images/Maitriser le langage Python2.png",
      "/images/Maitriser le langage Python3.png",
      "/images/Maitriser le langage Python4.png",
    ],
  },
  {
    id: 'Transaction-immobilière',
    gallery: [
      "/images/Transaction immobilière.png",
      "/images/Transaction immobilière1.png",
      "/images/Transaction immobilière2.png",
      "/images/Transaction immobilière3.png",
      "/images/Transaction immobilière4.png",
    ],
  },
  {
    id: 'Bilan-de-Compétences',
    gallery: [
      "/images/Bilan de Compétences Bilan d’orientation.png",
      "/images/Bilan de Compétences Bilan d’orientation1.png",
      "/images/Bilan de Compétences Bilan d’orientation2.png",
      "/images/Bilan de Compétences Bilan d’orientation3.png",
      "/images/Bilan de Compétences Bilan d’orientation4.png",
    ],
  },
  {
    id: 'Langue-des-signes-LSF',
    gallery: [
      "/images/Langue des signes LSF.png",
      "/images/Langue des signes LSF1.png",
      "/images/Langue des signes LSF2.png",
      "/images/Langue des signes LSF3.png",
      "/images/Langue des signes LSF4.png",
    ],
  },
  {
    id: 'Français',
    gallery: [
      "/images/Français.png",
      "/images/Français1.png",
      "/images/Français2.png",
      "/images/Français3.png",
      "/images/Français4.png",
    ],
  },
  {
    id: 'Anglais',
    gallery: [
      "/images/Anglais.png",
      "/images/Anglais1.png",
      "/images/Anglais2.png",
      "/images/Anglais3.png",
      "/images/Anglais4.png",
    ],
  },
  {
    id: 'Allemand',
    gallery: [
      "/images/Allemand.png",
      "/images/Allemand1.png",
      "/images/Allemand2.png",
      "/images/Allemand3.png",
      "/images/Allemand4.png",
    ],
  },
  {
    id: 'Espagnol',
    gallery: [
      "/images/Espagnol.png",
      "/images/Espagnol1.png",
      "/images/Espagnol2.png",
      "/images/Espagnol3.png",
      "/images/Espagnol4.png",
    ],
  },
  {
    id: 'Italien',
    gallery: [
      "/images/Italien.png",
      "/images/Italien1.png",
      "/images/Italien2.png",
      "/images/Italien3.png",
      "/images/Italien4.png",
    ],
  },
  {
    id: 'Portugais',
    gallery: [
      "/images/Portugais.png",
      "/images/Portugais1.png",
      "/images/Portugais2.png",
      "/images/Portugais3.png",
      "/images/Portugais4.png",
    ],
  },
  {
    id: 'Néerlandais',
    gallery: [
      "/images/Néerlandais.png",
      "/images/Néerlandais1.png",
      "/images/Néerlandais2.png",
      "/images/Néerlandais3.png",
      "/images/Néerlandais4.png",
    ],
  },
];
