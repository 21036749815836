export const destinations = [
  {
    id:"Bilan-de-Compétences",
    title: "Bilan de compétences",
    content: "Révélez vos talents avec le bilan de compétences !",
    category: "black",
    promo:"Votre préféré",
    src: "/images/Bilan de Compétences Bilan d’orientation.png",
    srcSet: "/images/Bilan de Compétences Bilan d’orientation.png",
    url: "/formations",
  },
  {
    id:"Les-fondamentaux-du-management",
    title: "Les fondamentaux du management",
    content: "Découvrir les fondations du management de projet",
    category: "black",
    promo: "",
    src: "/images/Gestion de projet.png",
    srcSet: "/images/Gestion de projet.png",
    url: "/formations",
  },
  {
    id:"Gestion-des-conflits",
    title: "Gestion des conflits",
    content: "Permettre d'intégrer dans son management d'équipe la gestion de conflit",
    category: "black",
    promo: "",
    src: "/images/Gestion des conflits.png",
    srcSet: "/images/Gestion des conflits.png",
    url: "/formations",
  },
  {
    id:"Créer-et-animer-un-site-WEB",
    title: "Créer un site WEB",
    content: "Améliorer votre notoriété / visibilité (SEO/SEA)",
    category: "black",
    promo: "",
    src: "/images/Créer et animer un site WEB.png",
    srcSet: "/images/Créer et animer un site WEB.png",
    url: "/formations",
  },
  {
    id:"Sauveteur-Secouriste-du-travail",
    title: "Sauveteur secouriste",
    content: "Sauveteur secouriste au travail (SST)",
    category: "black",
    promo: "",
    src: "/images/Sauveteur Secouriste du travail ( SST ).png",
    srcSet: "/images/Sauveteur Secouriste du travail ( SST ).png",
    url: "/formations",
  },
  {
    id:"Créer-son-entreprise-de-A-à-Z",
    title: "Création d’entreprise de A à Z",
    content: "Création d’entreprise de A à Z",
    category: "black",
    promo: "",
    src: "/images/Créer son entreprise de A à Z.png",
    srcSet: "/images/Créer son entreprise de A à Z.png",
    url: "/formations",
  },
];
