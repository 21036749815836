import React from "react";
import cn from "classnames";
import styles from "./Gallery.module.sass";


const Gallery = ({ items, type }) => {


  return (

    <div className={cn(styles.list, { [styles.tour]: type === "tour" })}>
      {items.map((x, index) => (
        <div className={styles.preview} key={index}>
          <img src={x} alt="Formation" loading="lazy"/>
        </div>
      ))}
    </div>

  );

};

export default Gallery;
