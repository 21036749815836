import React from "react";
import cn from 'classnames';
import Main from "./Main";
import styles from "./Cars.module.sass"



const Cars = () => {
  return (
    <>
      <Main />
      <div className={cn(styles.section)}>
        <div className="container">
          <h2>Wake Up Success</h2>
          <p>Champ d'application Les présentes conditions. générales de vente et de fourniture de services s'appliquent, sans restriction ni réserve, à l'ensemble des produits et des services proposés par la SAS WAKE UP SUCCESS
            A défaut de dispositions particulières stipulées par écrit, le fait de passer commande à la SAS WAKE UP SUCCESS implique l'acceptation pleine et entière de nos conditions générales de vente et de services.
          </p>
          <br />
          <ul>
            <li>SAS WAKE UP SUCCESS au capital de 1000€</li>
            <li>SIRET: 92060431100018</li>
            <li>Siège social : 50 Avenue du Peuple Belge 59000 Lille</li>
            <li>infos@wake-up-success.fr</li>
            <li>Mise à jour le  : 16/02/2024</li>
          </ul>
          <br />
          <h2>Article 1 - Objet</h2>
          <p>Les présentes conditions générales de vente ont pourobjet de définir les droits et obligations de la SAS WAKE UP SUCCESS et de ses clients. Seules les conditions particulières énoncées s'il y a lieu dans lesdevis, bons de commandes ou propositions commerciales, les présentes conditions générales et les dispositions Code du Commerce réglementent les conditions de vente des produits et services de la SAS WAKE UP SUCCESS
            Elles prévaudront sur toutes clauses et conditions contraires pouvant figurer sur les commandes ou autres documents émanant du client.
          </p>
          <br />
          <h2>Article 2-Documents contractuels</h2>
          <p>Après analyse de la demande exprimée, la SAS WAKE UP SUCCESS adresse une proposition de mission de conseil comprenant les éléments suivants: objet et objectifs de la demande, note d'appréhension et d'analyse, présentation d'une méthodologie de travail, moyens humains et matériels, calendrier prévisionnel, modalités financières. En cas d'accord, le client renvoie la proposition datée, signée, revêtue de la mention  "bon pour accord" portant son cachet, accompagné du chèque d'acompte.</p>
          <br />
          <h2>Article 3 - Tarification et contenu de la prestation</h2>
          <p>Le prix de la prestation est ferme. Il est stipulé horstaxes et exprimé en euros portant sur le montant totalà payer. II est à majorer de la TVA en vigueur. A ceprix s'ajoutent les frais de déplacement, le caséchéant.
            Les conditions de l'offre concernent exclusivement les prestations spécifiées aux devis et/ou aux bons de commande. Seules les corrections demandées par le client et comprises dans le bon de commande seront apportées, toute correction ou prestation supplémentaire et non prévue sur le devis, bon de commande ou proposition commerciale fera l'objet d'une facturation supplémentaire. Le refus de paiement de tout ou partie d'une telle prestation ouvrira le droit pour la SAS WAKE UP SUCCESS à la résiliation après mise en demeure envoyée en LRAR et au paiement d'une indemnité égale au préjudice subi et/ou à la valeur de la prestation. </p>
          <br />
          <h2>Article 4 - Modalités de paiement</h2>
          <p>Échéances de paiements: 40% à la signature du bon de commande. A l'issue de la prestation, la SAS WAKE UP SUCCESS adressera une facture à l'entreprise au à l'organisme. Le règlement devra être effectué parchèque par virement ou par mandat libellé à l'ordre de SAS WAKE UP SUCCESS à 30 jours fin de mois de réception de la facture.
            Tout retard de paiement donnera lieu à des pénalités de retard calculées suivant les règles légales en vigueur. Le taux des pénalités de retard de paiement est égal au taux d'intérêt appliqué par la Banque Centrale Européenne à son opération de refinancement la plus récente, majoré de 5 points(c.com. Art L 441-6, al 3 modifié). Une indemnité forfaitaire de 40 € est due au créancierpour frais de recouvrement, à l'occasion de tout retard de paiement (code du commerce art L442-6). Lespénalités de retard sont exigibles sans qu'un rappel soit nécessaire. En cas d'accord de paiement échelonné entre lesparties, le non-paiement d'une seule échéance entraînera de plein droit et à la seule initiative de la SAS WAKE UP SUCCESS la déchéance du terme après mise en demeure envoyée par LRAR.
          </p>
          <br />
          <h2>Article 5 - Délais et livraison</h2>
          <p>La SAS WAKE UP SUCCESS s'engage à tout mettre en œuvre pour procéder à la réalisation des prestations commandées par le client, mais ne souscrit aucune obligation de délais, sauf convention particulière. La réalisation des prestations est notamment tributaire dela fourniture par le client des informations nécessairesà la réalisation de la commande.</p>
          <br />
          <h2>Article 6 Nature des obligations 6.1 </h2>
          <p>Obligations du prestataire
            Pour l'accomplissement des prestations prévues, la SAS WAKE UP SUCCESS s'engage à donnerses meilleurs soins, conformément aux règles de l'art. La présente obligation est une obligation de moyens.
          </p>
          <br />
          <h2>Article 6.1.1 :</h2>
          <p>Le prestataire appelé à travailler dans les locauxdu client dans le cadre des prestations à effectuer devra se conformer aux prescriptions (règlement intérieur, code d'entreprise etc.)</p>
          <br />
          <h2>Article 6.1.2 :</h2>
          <p>Le prestataire signalera au client tous les éléments lui apparaissant de nature à compromettre la bonne exécution de la prestation.</p>
          <br />
          <h2>6.2 Obligations du client</h2>
          <p>Le client s'engage à collaborer avec la SAS WAKE UP SUCCESS en mettant à disposition, toutes les informations utiles, ainsi que les moyens techniques et humains, nécessaires à la bonne exécution de la prestation et au respect des délais d'exécution.
            Le client s'engage à désigner pour la durée de laprestation, une personne qualifiée ayant la responsabilité de prendre ou de faire prendre toute décision au nom du client et à mettre en rapport le personnel de la SAS WAKE UP SUCCESS réalisant cette prestation avec les membres de son personnel concerné par la réalisation de cette prestation.
          </p>
          <br />
          <h2>Article 6.2.1 :</h2>
          <p>Le client s'engage à communiquer au prestataire toute information dans la mesure où celle-ci est nécessaire à la bonne réalisation de la prestation.</p>
          <br />
          <h2>Article 7 - Droit de publicité</h2>
          <p>Sauf mention contraire explicite du client, la SAS WAKE UP SUCCESS se réserve le droit de mentionner saréalisation pour la société cliente comme référence dans le cadre de ses démarches de prospection commerciale, de communication externe et de publicité.</p>
          <br />
          <h2>Article 8 -Force majeure</h2>
          <p>La SAS WAKE UP SUCCESS ne pourra être tenue pour responsable d'un délai non respecté pour cause de tout cas fortuit ou de force majeure comme, en particulier, tout acte émanant d'une autorité civile ou militaire, de fait ou de droit de grève, incendie, inondation, dégâts des eaux, tempête et foudre, accident, émeute, attentat, tout fait imputable à un tiers, ou autre circonstance ayant une cause externe et l'empêchant, directement ou au travers d'un tiers, de répondre aux dites obligations.</p>
          <br />
          <h2>Article 9 Annulation ou réalisation partielle</h2>
          <p>Toute résiliation ou modification doit être faite au plustard 15 jours avant la date de début de la prestation.En cas d'annulation ou de renoncement par le Clientau-delà de ce délai, la SAS WAKE UP SUCCESS facturera
            Une indemnité de 40% du coût de la prestation. En casde réalisation partielle de la prestation pour des raisons imputables à l'entreprise, la SAS WAKE UP SUCCESS facturera au client, le coût de la prestation restant à réaliser.
          </p>
          <br />
          <h2>Article 10</h2>
          <p>Limitation de responsabilité La SAS WAKE UP SUCCESS n'est soumise qu'à uneobligation de moyens envers le client. La responsabilité du prestataire ne saurait être recherchée pour des dommages résultant d'erreurs provenant de documents ou d'informations fournies par le client.
            Dans le cas où la responsabilité de la SAS WAKE UP SUCCESS serait engagée à l'occasion de la réalisationde la prestation, le client ne pourra réclamer les dommages et intérêts que dans la limite du prix de vente hors taxes de la prestation, et ce quel que soitla nature du préjudice. La responsabilité globale de la SAS WAKE UP SUCCESS au titre des présentes est limitée aux seuls dommages matériels directs causésau Client résultant de fautes dûment prouvées qui lui seraient imputables. En aucune circonstance, la SAS WAKE UP SUCCESS ne sera tenue d'indemniser les dommages immatériels consécutifs ou non à un dommage matériel tels que notamment, les pertes d'exploitation, les pertes de production, le manque à gagner, la perte de profits, la perte de contrat, la perte d'image, la perte d'une chance, le préjudice commercial, les surcoûts de production, ainsi que tout dommage indirect. Le client et ses assureurs dont il se porte fort déclarent renoncer à tout recours contre la SAS WAKE UP SUCCESS au-delà des limites et exclusions ci- dessus exposées.
            En cas d'inexécution de tout ou partie de ses prestations pour des raisons qui lui sont imputables, laSAS WAKE UP SUCCESS ne pourra être tenue responsable que des dommages directs et ceci, dans la limite de 30% du montant des honoraires de la prestation concernée. Ceci exclut toute inexécution ouexécution fautive du fait d'un cas de force majeure visés à l'article 8 ci-après ou du fait du client.
          </p>
          <br />
          <h2>Article 11 - Confidentialité</h2>
          <p>Tant pendant le cours du présent Contrat qu'après son expiration pour quelque cause que ce soit, les parties garderont strictement confidentielles et s'interdiront d'utiliser les informations confidentielles de toute nature qu'elles auront été amenées à connaître sur leurs activités et leur entreprise respectives. Cette obligation de confidentialité perdure tant que les informations confidentielles ne sont pas tombées dansle domaine public. Les Parties s'engagent à utiliser les informations confidentielles qu'aux seules fins d'exécution du Contrat.</p>
          <br />
          <h2>Article 12-Juridiction</h2>
          <p>Tout litige relatif à l'interprétation et à l'exécution des présentes conditions générales de vente est soumis au droit français. A défaut de résolution amiable, le Tribunal de Commerce de Lille sera seul compétent pour tout litige relatif à l'interprétation et l'exécution d'un contrat et de ses suites.</p>
        </div>
      </div>
    </>
  );
};

export default Cars;
