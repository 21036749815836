import cn from "classnames";
import styles from "./Actions.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Share from "../Share";

const Actions = ({ className }) => {
    return (
        <div className={cn(className, styles.actions)}>
            <div className={styles.list}>
                <Share />
                <Link
                    className={cn(
                        "button-circle-stroke button-small",
                        styles.button
                    )}
                    to="/formations"
                >
                    <Icon name="close" size="24" />
                </Link>
            </div>
        </div>
    );
};

export default Actions;
