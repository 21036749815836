import React from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "../Control";
import Icon from "../Icon";
import Actions from "../Actions";
import Gallery from "./Gallery";

const Product = ({
  classSection,
  title,
  gallery,
}) => {
  return (
    <div className={cn(classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <Control
          className={styles.control}
        />
        <div className={styles.head}>
          <div className={styles.box}>
            <h1 className={cn("h2", styles.title)}>{title}</h1>
            <div className={styles.line}>
              <div className={styles.options}>
                <div className={styles.option}>
                  <Icon name="accessible" size="40" />
                  <p>
                    Nos formations sont accessibles aux personnes en situation de handicap , pour toutes questions relatives , veuillez contacter notre référent handicap : Rachid TEBBOUB rtebboub@wake-up-success.fr
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Actions className={styles.actions} />
        </div>
        <Gallery className={styles.gallery} items={gallery} type="tour" />
      </div>
    </div>
  );
};

export default Product;
